import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useState, useEffect } from 'react';
import { useAuth } from '../../services/authContext';
import { Button, IconButton, TextField, Tooltip } from '@material-ui/core';
import { CreateNewFolderRounded, SaveRounded, ClearRounded } from "@material-ui/icons";
import { getUsersFolder, addParentFolder, updateFileFolder } from  '../../services/api';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';

const MoveFile = ({ isOpenMoveFile, handleCloseMoveFile, selectedFile }) => {
    const { user } = useAuth();
    const [usersFolder, setUsersFolder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [selectedFolderId, setSelectedFolderId] = useState('');
    const [addingFolderParentId, setAddingFolderParentId] = useState(null);
    const [editingFolderId, setEditingFolderId] = useState(null);
    const [parentFolderValue, setParentFolderValue] = useState('');

    function buildTree(data) {
      const idToNodeMap = {};
      const resultTree = [];
    
      data.forEach((item) => {

        // Map each item by Id for easy reference
        idToNodeMap[item.id] = { 
          title: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {editingFolderId === item.id ? (
              <TextField
                defaultValue={item.name}
                size="small"
                onBlur={(e) => handleRenameFolder(e, item.id)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleRenameFolder(e, item.id);
                }}
                autoFocus
              />
            ) : (
              <>
              {item.name}
                  {item.isParent && (
                    <>
                    <Tooltip title="Add a folder" arrow placement='right' >
                    
                      <IconButton
                      size="small"
                      color="default"
                      onClick={(e) => handleAddSubFolder(e, item.id)}
                      style={{ marginLeft: 8 }}>
                          <CreateNewFolderRounded fontSize="small" />
                      </IconButton>

                    </Tooltip>
                      {/* <IconButton
                        size="small"
                        color="secondary"
                        onClick={(e) => handleEditFolder(e, item.id)}
                        style={{ marginLeft: 4 }}>
                          <Edit fontSize="small" />
                      </IconButton> */}
                      </>
                  )}
              </>
            )}

            </div>
          ),
          key: item.id,
          isLeaf: !item.isParent, // Mark files (not folders) as leaf nodes
          children: [
            ...(item.files && item.files.length > 0
              ? item.files.map((file) => ({
                  title: file.name,
                  key: `file-${file.id}`,
                  isLeaf: true
                }))
              : [])
          ]
        };
      });
    
      data.forEach((item) => {
        if (item.parentId) {
          idToNodeMap[item.parentId].children.push(idToNodeMap[item.id]);
        } else {
          resultTree.push(idToNodeMap[item.id]);
        }
      });

       // Add TextField for the new folder if addingFolderParentId is set
       if (addingFolderParentId && idToNodeMap[addingFolderParentId]) {
        idToNodeMap[addingFolderParentId].children.push({
          title: (
            <TextField
              size="small"
              placeholder="New folder name"
              onBlur={(e) => handleCreateFolder(e, addingFolderParentId)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleCreateFolder(e, addingFolderParentId);
              }}
              autoFocus
            />
          ),
          key: `new-folder-${addingFolderParentId}`,
          isLeaf: true
        });
      }
    
      return resultTree;
    }
    
    const handleRenameFolder = (e, folderId) => {
      const newName = e.target.value.trim();
      console.log(newName);
      console.log(folderId);
      // if (newName) {
      //   console.log(`Rename folder with ID: ${folderId} to: ${newName}`);
      //   setUsersFolder((prevData) =>
      //     prevData.map((item) =>
      //       item.id === folderId ? { ...item, name: newName } : item
      //     )
      //   );
      // }
      setEditingFolderId(null);
    };

    // const handleEditFolder = (e, folderId) => {
    //   e.stopPropagation();
    //   setEditingFolderId(folderId); // Set the folder in edit mode
    // };
  
    const handleAddSubFolder = (e, parentId) => {
      e.stopPropagation();
      setAddingFolderParentId(parentId);
    };

    const handleCreateFolder = async (e, parentId) => {
      if(e.target.value == '')
      {
        setAddingFolderParentId(null);
        return; 
      }

      const folderName = e.target.value.trim();
      if (folderName) {
        
        const payload = 
        {
          id : 0,
          name : folderName,
          isParent: true,
          parentId: parentId,
          userId: 0,
          email: user.email,
          fileId: selectedFile.id
        };

        var response = await addParentFolder(payload);
        setUsersFolder(response);

        const updatedTreeData = buildTree(response);
        setTreeData(updatedTreeData);
      }

      setAddingFolderParentId('');
    };

    const handleAddParentFolder = async () =>
    {
      const payload = 
      {
        id : 0,
        name : parentFolderValue,
        isParent: true,
        parentId: null,
        userId: 0,
        email: user.email,
        fileId: 0
      };

      var response = await addParentFolder(payload);

      setUsersFolder(response);
        
      const updatedTreeData = buildTree(response);
      setTreeData(updatedTreeData);

      setParentFolderValue('');

    }

    const onSelect = (selectedKeys, info) => {
      const { node } = info;
      if (!node.isLeaf) {
        // Only set the ID if it’s a folder (not a file)

        if(selectedKeys.length == 0)
        {
          setSelectedFolderId('');
          return;
        }

        const selectedFolderId = selectedKeys[0];
        console.log('Selected Folder ID:', selectedFolderId);
        setSelectedFolderId(selectedFolderId);
      } else {
        setSelectedFolderId('');
      }

    };
  
    const onExpand = (expandedKeys) => {
      console.log('expanded', expandedKeys);
    };
      
    const handleUpdateFileFolder = async () => 
    {
      if(selectedFolderId == '')
      {
        console.log('No selected folder');
        return; 
      }

      const payload = 
      {
        id : selectedFolderId,
        name : parentFolderValue,
        isParent: false,
        parentId: null,
        userId: 0,
        email: user.email,
        fileId: selectedFile.id
      };

      var response = await updateFileFolder(payload);

      setUsersFolder(response);
        
      const updatedTreeData = buildTree(response);
      setTreeData(updatedTreeData);
    }

    useEffect( () => {
      const fetchUsersFolder = async () =>
        {
          setLoading(true);
    
          try
          {
            if(user == null)
            {
              return console.error('user is empty');
            }

            const result = await getUsersFolder(user.email);

            setUsersFolder(result);
          }
          catch(error)
          {
            console.error("Error fetching folders:", error);
          }
          finally
          {
            setLoading(false);
          }
        };

        fetchUsersFolder();
    }, [user]);

    useEffect(() => 
    {
      const transformedData = buildTree(usersFolder);
      setTreeData(transformedData);

    },[usersFolder, addingFolderParentId, editingFolderId])


    return (
        <Modal
            open={isOpenMoveFile}
            onClose={handleCloseMoveFile}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
              onClick: (e) => e.stopPropagation(),
            }}
            >
              
            <div className="modalContent"
                style={{
                    width: '500px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    margin: 'auto',
                }}>
                <div>
                    <button
                        onClick={handleCloseMoveFile}
                        className="modalBtnClose">
                        &times;
                    </button>
                </div>
                <div style={{marginTop: '10px', marginBottom: '20px'}}>
                  {/* <Button color='secondary' size='small' variant="outlined" 
                  startIcon={<CreateNewFolderRounded />} style={{marginRight: '7px'}}>
                      Add a Folder
                  </Button> */}
                  
                  <TextField
                   size="small"
                  variant='outlined'
                  value={parentFolderValue}
                  onChange={(event) => {setParentFolderValue(event.target.value)}}
                  label='Folder Name'
                  >

                  </TextField>
                  <div style={{display: 'inline-flex', marginTop: '5px'}}>
                  
                  <Tooltip title='Clear' arrow placement='bottom' >
                  {/* disabled={parentFolderValue == ''} */}
                    <IconButton size='small' onClick={() => {setParentFolderValue('')}}>
                      <ClearRounded>Clear</ClearRounded>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Save new folder' arrow placement='bottom' >
                  {/* disabled={parentFolderValue == ''} */}
                    <IconButton size='small' color='primary'onClick={handleAddParentFolder}>
                      <SaveRounded>Save</SaveRounded>
                    </IconButton>
                  </Tooltip>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  {loading ? 'loading..' : 
                  <>
                      <Tree
                      treeData={treeData}
                      onSelect={onSelect}
                      onExpand={onExpand}
                      defaultExpandAll
                      />
                  </>
                  }

                </div>
                <div style={{ float: 'right', marginTop: '20px' }}>
                  {treeData.length == 0 ? '' :
                      <Button color='primary' size='small' variant="outlined" 
                      startIcon={<SaveRounded />}
                      onClick={handleUpdateFileFolder}>
                        Save
                      </Button>
                  }
                </div>
            </div>
        </Modal>
    );
}

MoveFile.propTypes = {
    isOpenMoveFile: PropTypes.bool.isRequired,
    handleCloseMoveFile: PropTypes.func.isRequired,
    selectedFile: PropTypes.object.isRequired
};

export default MoveFile;