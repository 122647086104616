import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
//import RTL from "layouts/RTL.js";
import LoginPage from "./components/LoginPage"; // Correct the import path
import SignupPage from './components/SignupPage.js';
import { AuthProvider } from "./services/authContext"; // Correct the import path
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "Config"; 
import "assets/css/material-dashboard-react.css?v=1.10.0";

const msalInstance = new PublicClientApplication(msalConfig);
// Render the application.
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
  <AuthProvider>
  <BrowserRouter>
    <Switch>
    <Route path="/login" component={LoginPage} />
    <Route path="/signup" component={SignupPage} />
      <Route path="/admin" component={Admin} />
      {/* <Route path="/rtl" component={RTL} /> */}
      <Redirect from="/" to="/login" /> {/* Redirect to login page */}
    </Switch>
  </BrowserRouter>
  </AuthProvider>
  </MsalProvider>,
  document.getElementById("root")
);
