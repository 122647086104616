import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createAccount, loginAccount } from '../services/api';
import { useAuth } from '../services/authContext';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #ece9e6 0%, #ffffff 100%)',
  },
  paper: {
    padding: theme.spacing(4),
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
    borderRadius: '12px', 
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', 
    background: '#ffffff', 
  },
  header: {
    color: '#3f51b5', 
    marginBottom: theme.spacing(3),
    fontWeight: 'bold', 
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(2), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#3f51b5', 
    color: 'white',
    '&:hover': {
      backgroundColor: '#2c387e', 
    },
    borderRadius: '8px', 
  },
  errorMessage: {
    color: 'red', 
    marginBottom: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(2),
    color: '#3f51b5',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const SignupPage = () => {
  const classes = useStyles();
  const { login } = useAuth();
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    
    if (name === 'email') {
      setEmailError('');
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    console.log(formData);
    setErrorMessage('');
    setEmailError(''); 
  
    // Validate email format
    if (!emailPattern.test(formData.email)) {
      setEmailError('Please enter a valid email address.');
      return; 
    }
  
    try {
      // Call the createAccount function and handle the response
      const result = await createAccount(formData);
      console.log("Account created successfully:", result);
  
      // Prepare login credentials after successful signup
      const loginCredentials = { email: formData.email, password: formData.password };
      
      // Attempt to log in the user
      const loginResult = await loginAccount(loginCredentials);
      console.log("User logged in successfully:", loginResult);
      login({ email: loginResult.email, name: loginResult.name, profilePicture: loginResult.profilePicture });
      history.push('/admin/dashboard'); 
    } catch (error) {
      console.error("Signup failed:", error);
      if (error.response && error.response.status === 400) {
        // Check if error.response.data is an object and extract the message
        const message = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
        setErrorMessage(message); 
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.'); // Generic error message
      }
    }
  };
  

  return (
    <Container component="main" className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h5">Sign Up</Typography>
        {errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
        <form className={classes.form} onSubmit={handleSignup}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!emailError} 
            helperText={emailError} 
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Create a new account
          </Button>
        </form>
        <Typography variant="body2">
          Already have an account? <Link to="/login" className={classes.link}>Log In</Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default SignupPage;
