import {getCookie} from "components/Utils/cookies";
console.log('Backend URL:', process.env.REACT_APP_API_URL);
import axios from "axios";
//const baseURL2 = process.env.REACT_APP_BASE_URL || 'https://localhost:7218';
//production
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

console.log('Redirect Url',process.env.REACT_APP_REDIRECT_URL); 
//development
// const apiClient = axios.create({
//   baseURL: "https://wa-audioly-dev-aueast-002.azurewebsites.net",
// });

//local
// const apiClientlocalhost = axios.create({
//   baseURL: "https://localhost:7218",
// });

const handleUnauthorizedError = () => {
  document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  window.location.href = '/login';
};

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    return Promise.reject(error);
  }
);

export const loginAccount = async (credentials) => {
  try{
    const response = await apiClient.post("/api/User/Login", credentials);
    return response.data;
  } catch (error) { 
    console.error("Login API failed:", error);
    throw error; 
  }
};
export const loginThirdPartyAccount = async (credentials) => {
  try {
    const response = await apiClient.post("/api/User/LoginThirdParty", credentials);
    return response.data;
  } catch (error) {
    console.error("Google login API failed:", error);
    throw error; // Let the caller handle it
  }
};
export const fetchData = async () => {
  try {
    const response = await apiClient.get("/data"); // Replace with your API endpoint
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (data) => {
  try {
    const response = await apiClient.post("/data", data); 
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const createAccount = async (data) => {
  try {
    const response = await apiClient.post("/api/User/CreateAccount", data); 
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const fetchFilesByEmail = async (email) => {
  try {
    // const response = await fetch(
    //   `https://wa-audioly-dev-aueast-002.azurewebsites.net/api/File/GetAllByEmail?email=${email}`
    // );
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/File/GetAllByEmail", {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data; 
    if (!Array.isArray(data)) {
      throw new Error("Expected data to be an array");
    }
    return data;
  } catch (error) {
    console.error("Error in fetchFilesByEmail:", error);
    throw error;
  } 
}; 

export const GetFilesAndFolders = async(email) => 
{
  try{
    const token = getCookie('jwtToken');

    const response = await apiClient.get("/api/Folder/GetFilesAndFolders", {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data; 

    return data;
  }
  catch(error)
  {
    console.error("Error in fetching files and folders:", error);
    throw error;
  }
}


export const getFileWithSasUrl = async (fileId) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/Upload/GetFileWithSasUrl", null,
      {
        params: { fileId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response.data;
  }
  catch (error) {
    console.error("Error fetching transcription status:", error);
    throw error;
  }
};

export const getUser = async (email, name) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/User/GetUser", {
      params: { email, name },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error in getUser:", error);
    throw error;
  } 
};


export const checkConvertedFile = async (email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/Upload/CheckConvertedFile", null, {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkConvertedFile:", error);
    throw error;
  }
};

// Call CheckRunningBatchTranscriptionV2 API
export const checkRunningBatchTranscription = async (email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/Transcription/CheckRunningBatchTranscriptionV2", null, {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkRunningBatchTranscription:", error);
    throw error;
  }
};

export const checkRunningBatchReTranscription = async (email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/Transcription/CheckRunningBatchReTranscription", null, {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkRunningBatchTranscription:", error);
    throw error;
  }
};
// Delete audio
export const deleteAudio = async (id, url) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post(
      "/api/File/DeleteAudio",
      { id, url }, // Request payload
      {
        headers: {
          Authorization: `Bearer ${token}`, // Authorization header
        },
      }
    ); // Replace with your API endpoint
    return response;
  } catch (error) {
    console.error("Error deleting audio:", error);
    throw error;
  }
};

export const reTranscribe = async (fileId, transcriptionId) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/Transcription/ReTranscribe", null,
      {
        params: { fileId, transcriptionId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    return response;
  }
  catch (error) {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}
export const reTranscribeV2 = async (fileId, transcriptionId, customModel, email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/Upload/ReTranscribe", null,
      {
        params: { fileId, transcriptionId, customModel, email },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response;
  }
  catch (error) {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}

export const checkTranscriptionStatus = async (transcriptionId) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/transcription/GetTranscriptionStatus",
      {
        params: { transcriptionId },
        headers: {
          Authorization: `Bearer ${token}`, 
        }

      });

    return response.data;
  }
  catch (error) {
    console.error("Error fetching transcription status:", error);
    throw error;
  }
}

export const getDashboardDetails = async (email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/dashboard/GetDashboardDetails",

      {
        params: { email },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );

    return response.data;
  }
  catch (error) {
    console.error("Error fetching dashboard details: ", error);
    throw error;
  }
}

export const getUserProfilePhoto = async (accessToken) => {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };

    return fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options)
      .then(response => {
        if (!response.ok) {
          return '';
        }

        return response.blob();
      })
      .then(blob => {
        // Create a URL for the image
        const imageUrl = blob != '' ? URL.createObjectURL(blob) : '';
        return imageUrl;
      });

  }
  catch (error) {
    console.error("Error fetching user photo:", error);
    throw error;
  }
}

export const getModelTranscriptionResource1 = async () => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/Upload/GetModelTranscriptionResource1", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};


export const UpsertTranscriptionWord = async (payload) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post(
      "/api/TranscriptionWord/Upsert",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error in UpsertTranscriptionWord:", error);
    throw error;
  }
};


export const InsertCustomButton = async (payload) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post(
      "/api/QandA/InserCustomButton",
       payload,
      {
        headers: {  
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  }
  catch (error) {
    console.error(error);
  }
}
export const UpdateCustomButton = async (payload) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/QandA/UpdateCustomButton", 
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },  
      }
    );

    return response;
  }
  catch (error) {
    console.error(error);
  }
}

export const SendtoAzureChat = async (transId, questionText, email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/QandA/SendtoAzureAIchat", null,
      {
        params: { transId, questionText, email },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response;
  }
  catch (error) {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}
export const clearChatHistory = async (transId, email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/QandA/DeleteChatHistoryByTransId", null,
      {
        params: { transId, email },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response;
  }
  catch (error) {
    console.error("Error clearing chat history:", error);
    throw error;
  }
}
export const GetCustomButtons = async (email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/QandA/GetCustomButton", {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


    return response.data.map(btn => ({
      icon: btn.icon,
      text: btn.questionText,
      id: btn.id
    }));
  } catch (error) {
    console.error("Error fetching custom buttons:", error);
    throw error;
  }
}
export const getChatHistory = async (transId, email) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.get("/api/QandA/GetConversationHistory", {
      params: { transId, email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) { 
    console.error("Error fetching chat history:", error);
    throw error;
  }
}

export const Log = async (payload) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/log/log",
       payload,
       {
        headers: {
          Authorization: `Bearer ${token}`,
        },
       }
      );

    return response;
  }
  catch (error) {
    console.error(error)
  }
}
export const deleteCustomButton = async (id) => {
  try {
    const token = getCookie('jwtToken');
    const response = await apiClient.post("/api/QandA/DeleteCustomButton", null, {
      params: { id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error deleting custom button:", error);
    throw error;
  }
}


export const deleteTranscriptionWord = async (id, transcriptionId) =>
{
  try
  {
    const token = getCookie('jwtToken');
    const response = await apiClient.delete("/api/transcriptionWord/delete", {
      params: { 
        id : id, 
        transcriptionId: transcriptionId
       },
       headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }
  catch(error)
  {
    console.error("Error deleting custom button:", error);
    throw error;
  }
}

export const getTranscriptionDetailsByTimestamp = async (id, startTime, endTime) =>
{
  try
  {
    const token = getCookie('jwtToken');
    var response = await apiClient.get("/api/transcriptionWord/getTranscriptionDetailsByTimestamp",
      {
        params: 
        {
          transcriptionId: id,
          startTime: startTime,
          endTime: endTime
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response.data;
  }
  catch(error)
  {
    console.error("Error deleting custom button:", error);
    throw error;
  }
}

export const getUsersFolder = async (email) =>
{
  try
  {
    const token = getCookie('jwtToken');
    var response = await apiClient.get("/api/folder/getAll",
      {
        params: 
        {
          email: email
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response.data;
  }
  catch(error)
  {
    console.error("Error fetching folders:", error);
    throw error;
  }
}

export const addParentFolder = async(payload) =>
{
  try
  {
    const token = getCookie('jwtToken');
    var response = await apiClient.post("/api/folder/add", 
      payload, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch(error)
  {
    console.error("Error saving parent folder:", error);
    throw error;
  }
}

export const updateFolder = async (payload) =>
  {
    try
    {
      const token = getCookie('jwtToken');
      var response = await apiClient.post("/api/folder/update", 
        payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response;
    }
    catch(error)
    {
      console.error("Error updating file folder:", error);
      throw error;
    }
  }

export const updateFileFolder = async (payload) =>
{
  try
  {
    const token = getCookie('jwtToken');
    var response = await apiClient.post("/api/folder/updateFileFolder", 
      payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }
  catch(error)
  {
    console.error("Error updating file folder:", error);
    throw error;
  }
}

